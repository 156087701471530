import React from 'react'
import Navbar from '../inc/Navbar'
import "./Contact.css";
import Mail from "../images/mailicon.jpg";
import Call from "../images/CALL.jpg";
import { Link } from "react-router-dom";
import Youtube from "../images/youtube-icon.jpg";
import Facebookicon from "../images/facebook-icon.jpg";
import ScrollToTop from "react-scroll-to-top";
import AddOnline from '../AddOnline';

function Cantactus() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br/>
    
      <ScrollToTop smooth top="100" color="grey"/>
      <div className='colorback'>
        <div className="album py-0 mt-3">

          <div className="container">

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="album py-3">

        <div className="container">
          <h1 style={{ color: "grey" }}><b>Contact us</b></h1>
          <p>We’re here to help you. Get in touch with us from any of these ways:</p>
          <hr className='' style={{ color: "grey" }} />
        </div>
      </div>


      <div className="container">

        <div className="row">

          <div className="col-md-3 mt-2">
            <div className="card">
              <a href='mailto: manmohini.berhampore@gmail.com'>
              <img src={Mail} className="card-img-top mb-0" alt="Mail" />
              <div className="card-body">
                <center>
                  <h6 style={{ color: "grey" }} className="card-title mb-0"><small>Write to us</small></h6>
                  <p style={{ color: "grey" }} className="card-text"><small>your valuable feedback</small></p>
                </center>
              </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-2">
            <div className="card">
              <a href='https://www.youtube.com/@Manmohinitextileprivatelimited' target="_blank" rel="noopener noreferrer">
                <img src={Youtube} className="card-img-top" alt="Youtube" />
                <div className="card-body">
                  <center>
                    <h6 style={{ color: "grey" }} className="card-title mb-0"><small>@Manmohinitextileprivatelimited</small></h6>
                    <p style={{ color: "grey" }} className="card-text"><small>Connect with us on your favourite social network.</small></p>
                  </center>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-2">
            <div className="card">
              <a href='https://www.facebook.com/manmohini.textile' target="_blank" rel="noopener noreferrer">
                <img src={Facebookicon} className="card-img-top" alt="Facebookicon" />
                <div className="card-body">
                  <center>
                    <h6 style={{ color: "grey" }} className="card-title mb-0"><small>manmohini.textile</small></h6>
                    <p style={{ color: "grey" }} className="card-text"><small>Connect with us on your favourite social network.</small></p>
                  </center>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-2">
            <div className="card">
              <a href='tel:  9733508786'>
                <img src={Call} className="card-img-top" alt="manmohini_contact" />
                <div className="card-body">
                  <center>
                    <h6 style={{ color: "grey" }} className="card-title mb-0"><small>Talk to us</small></h6>
                    <p style={{ color: "grey" }} className="card-text mb-0"><small>Everyday 10:00AM to 10:00PM</small></p>
                    <p style={{ color: "grey" }} className="card-text"><small>  Call us on 97335-08786</small></p>

                  </center>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
     
      <div className='album py-1'>
        <div className="container">

          <div className="row">
            <h6 className='mb-3' style={{color:"grey"}}>Your feedback is about our:</h6>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><small>Online experience</small></button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><small>In-store experience</small></button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"><small>Help Line</small></button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <br/>
                <AddOnline/>
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <br/>
                <AddOnline/>
              </div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <br/>
                <p className='mt-2 mb-3'>Any query please cantact : - </p>
                <a style={{color:"grey"}} href='tel: 9733508786'>
                  <p className='mb-1'>+91 97335 08786</p>
                  </a>
                <a style={{color:"grey"}} href='mailto: manmohini.berhampore@gmail.com'>manmohini.berhampore@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>



  )
}

export default Cantactus