import React from 'react'
import Navbar from '../inc/Navbar'
import Winter from './Winter'
import {Link} from "react-router-dom";

function Winterpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
       
        
<div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"black"}} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Winter Collection <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        <Winter/>
    </div>
  )
}

export default Winterpage