import React from 'react'
import Navbar from '../inc/Navbar'


import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import skincare from "../images/Skin Care.jpg";
import ladiesbag from "../images/ladies bag.jpg";


function Cosmetics() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{ color: "black" }} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Cosmetics <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
       <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='hair-and-beauty'>
                                        <div className="card">
                                            <img src={skincare} className="card-img-top" alt="Locketnew" />
                                            <div className="card-body">
                                            <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Hair & Beauty</h5>
                                            <p style={{ color: "grey" }} className="card-text"> Branded Skin Care Product at Manmohini</p>
                                            <Link to="/hair-and-beauty" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Ladiesbag'>
                                        <div className="card">
                                            <img src={ladiesbag} className="card-img-top" alt="ladiesbag"/>
                                            <div className="card-body">
                                            <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Ladies Bag</h5>
                                            <p style={{ color: "grey" }} className="card-text"> Branded ladies bag product at Manmohini</p>
                                            <Link to="/Ladiesbag" className="appbuttonmanmohinicall">Learn More</Link>
                                        </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                     

                    </div>
                </div>
            </div>
            <ScrollToTop smooth top="100" color="maroon" />
          
            <br />

        </div>
    )
}

export default Cosmetics;