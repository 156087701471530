
import React from 'react'
import "./About.css";
import Navbar from '../inc/Navbar';
import About2 from './About2';

function Aboutus() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
       
        <div className="manmohiniberhampore">
        <br/>
        <br/>
        <div className="album py-0">
        
        <div className="container">
                    <h1 className='mb-3'><b></b>Manmohini</h1>
                    <h3>ABOUT US</h3>
                    </div>
                    </div>
            
            </div>
            <br/>
            <br/>
            <center><h2 style={{color:"maroon"}} >Manmohini Textile Private Limited</h2> </center>
            <br/>
          <About2/>

    </div>
  )
}

export default Aboutus
