import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'


function Bannerslider() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [slider, setSlider] = useState([])
  const navigate = useNavigate()

  const sliderCollectionRef = collection(db, "Slider");
  useEffect(() => {

    const getSlider = async () => {
      const data = await getDocs(sliderCollectionRef);
      setSlider(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getSlider()
  }, [])
  return (
    <div>

      {slider.filter((val) => {
        if (setsearchTerm === "") {
          return val;
        } {
          return val;
        }
      }).slice(pagination.start, pagination.end).map((manmohini) => {
        return (



          <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={manmohini.slider1} className="d-block w-100" alt="manmohini" />
                <div className="carousel-caption d-none d-md-block">
                  <div className="d-flex justify-content-between align-items-start">

                    <div className='btn-grad13'> <h5 className='mt-1'> Her <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg></h5></div>


                    <div className='btn-grad13'> <h5 className='mt-1'> Him <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg></h5></div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img src={manmohini.slider2} className="d-block w-100" alt="manmohini" />
                <div className="carousel-caption d-none d-md-block">
                  <div className="d-flex justify-content-between align-items-start">

                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img src={manmohini.slider3} className="d-block w-100" alt="manmohini" />
                <div className="carousel-caption d-none d-md-block">
                  <div className="d-flex justify-content-between align-items-start">

                    <div className='btn-grad13'> <h5 className='mt-1'> Her <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg></h5></div>


                    <div className='btn-grad13'> <h5 className='mt-1'> Him <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg></h5></div>
                  </div>
                </div>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

        )
      })}

      {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={slider.length}
                /> */}

    </div>
  )
}

export default Bannerslider
