import React from 'react'
import Navbar from '../inc/Navbar'
import girlstop from "../images/Girls-top.jpg"

import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import tshirkids from "../images/t-shirtkids.jpg";

import Kidsslider from '../inc/Kidsslider';

function Kids() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{ color: "black" }} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Kidswear <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <Kidsslider />
            <ScrollToTop smooth top="100" color="maroon" />
            <div className='album py-2'>
                <div className="container">

                    <div className="row">

                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <a href='t-shirt'>
                                    <img src={tshirkids} className="card-img-top" alt="tshirkids" />
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">T-Shirt</h5>
                                            <p style={{ color: "grey" }} className="card-text">All Type T-Shirt Design & Color Available at Manmohini</p>
                                            <a href='t-shirt' className="appbuttonmanmohinicall">Learn More</a>
                                        </center>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card shadow" >
                                <a href='girls-top'>
                                    <img src={girlstop} className="card-img-top" alt="girlstop"/>
                                    <div className="card-body">
                                        <center>
                                            <h5 style={{ color: "darkblue" }} className="card-title">Girls Top</h5>
                                            <p style={{ color: "grey" }} className="card-text">All Type Top Design & Color Available at Manmohini</p>
                                            <a href='girls-top' className="appbuttonmanmohinicall">Learn More</a>
                                        </center>
                                    </div>
                                </a>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            <br />

        </div>
    )
}

export default Kids