import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'







function Careerupdate() {

    const [isExpanded, setIsExpanded] = useState(false);

const toggleExpanded = () => {
  setIsExpanded(!isExpanded);
};


    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [careerupdate, setCareerupdate] = useState([])
    const navigate = useNavigate()

    const careerupdateCollectionRef = collection(db, "CAREER-UPDATE-POST");
    useEffect(() => {

        const getCareerupdate = async () => {
            const data = await getDocs(careerupdateCollectionRef);
            setCareerupdate(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getCareerupdate()
    }, [])
    return (

        <>
         
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {careerupdate.filter((val) => {
                            if (setsearchTerm === "") {
                           
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                              
                            }
                        }).slice(pagination.start, pagination.end).map((careermanmohini) => {
                            return (
                                <div className="col-md-12 mt-3" >
                                    <div className="card mb-4 border-White box shadow">
                                        <img className="card-img-top" src={careermanmohini.img} alt="Career_Update"/>
                                        
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default Careerupdate;



