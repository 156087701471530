import React from 'react'
import Navbar from '../inc/Navbar'
import Shirt from "../images/shirt.jpg"

import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
// import Womenslider from '../inc/Womenslider';
import Saree from "../images/saree-new.jpg";
import Lehenga from "../images/lehenga.jpg";
import Top from "../images/TOP.jpg";
import Dresses from "../images/dresses.jpg";

import Jeans from "../images/jeans.jpg";
import JeanKurtasets from "../images/KURTA SETS.jpg";
import Ethnicwear from "../images/Ethnic Wear Women.jpg";
import Trousers from "../images/trousers -womwn.jpg";
import Lengerie from "../images/Lengerie.jpg";

function Womens() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{ color: "black" }} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Womens <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <Womenslider /> */}
            <ScrollToTop smooth top="100" color="maroon" />

            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='saree'>
                                        <div className="card shadow">
                                            <img src={Saree} className="card-img-top " alt="saree" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 style={{ color: "darkblue" }} className="card-title">Saree</h5>
                                                    <a href="saree" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='top-teas'>
                                        <div className="card shadow">
                                            <img src={Top} className="card-img-top" alt="top" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Top & Teas</h5>
                                                    <a href="top-teas" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Lehenga'>
                                        <div className="card shadow">
                                            <img src={Lehenga} className="card-img-top" alt="lehenga" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Lehenga</h5>
                                                    <a href="Lehenga" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='dresses'>
                                        <div className="card shadow">
                                            <img src={Dresses} className="card-img-top" alt="dresses" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Dresses</h5>
                                                    <a href="dresses" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Jeans-Women'>
                                        <div className="card shadow">
                                            <img src={Jeans} className="card-img-top" alt="Jeans" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Jeans</h5>
                                                    <a href="Jeans-Women" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Kurtaset'>
                                        <div className="card shadow">
                                            <img src={JeanKurtasets} className="card-img-top" alt="JeanKurtasets" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Kurta Sets</h5>
                                                    <a href="Kurtaset" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Women-Ethnic-wear'>
                                        <div className="card shadow">
                                            <img src={Ethnicwear} className="card-img-top" alt="Ethnicwear" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Ethnic Wear</h5>
                                                    <a href="Women-Ethnic-wear" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Women-Trousers'>
                                        <div className="card shadow">
                                            <img src={Trousers} className="card-img-top" alt="Trousers" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Trousers</h5>
                                                    <a href="Women-Trousers" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


         
            <div className='album py-2'>
                <div className="container">

                    <div className="row">



                        <div className="col-md-3 mt-3">
                            <a href='Lingeria'>
                            <div className="card shadow" >
                                <img src={Lengerie} className="card-img-top" alt="Lengerie" />
                                <div className="card-body">
                                    <center>
                                        <h5 className="card-title">Lingerie</h5>
                                        <a href="Lingeria" className="appbuttonmanmohinicall">Learn More</a>
                                    </center>
                                </div>
                            </div>
                            </a>
                        </div>


                    </div>
                </div>
            </div>
            <br />
            <br />

        </div>
    )
}

export default Womens