import React from 'react';
// import './Footer.css';
import { Link } from 'react-router-dom';

// import logo from "../images/logojewellers.jpg";
import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";
function Footer() {
  return (


    <div class="manmohini">
      <br></br>
      <br />
      {/* <div class="p-3 mb-0 bg-dark text-maroon"> */}

      <div class="album py-3">

        <div class="container">

          <div class="row">
            <div class="col-md-3 ">

              <h6 style={{ color: "maroon" }}>Showroom Address</h6>
              <hr />

              <ul class="list-unstyled">

                <h6 style={{ color: "maroon" }} class="card-text "> <b>MANMOHINI</b></h6>
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        MANMOHINI BERHAMPORE
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p style={{ color: "maroon" }} class="card-text mb-1">315/1 & 316/B, Netaji Road, Khagra, Berhampore</p>
                        <p style={{ color: "maroon" }} class="card-text mb-1">  Murshidabad, West Bengal, 742103, India</p>
                        <a href='https://www.google.com/maps/place/MANMOHINI/@24.111918,88.24783,10z/data=!4m6!3m5!1s0x39f97ddc54d49589:0xa412e9b56d898975!8m2!3d24.1119182!4d88.24783!16s%2Fg%2F1thl3vbb?hl=en&entry=ttu' target="_blank" rel="noopener noreferrer">
                          <p class='mb-2' >Direction</p>
                          </a>

                        <a class="appbuttonmanmohinicall me-1" href="tel: 97335 08786">+91 97335 08786 </a>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        MANMOHINI RAGHUNATHGANJ
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p style={{ color: "maroon" }} class="card-text mb-1">739/620/1. Lalgola Maharaja Road,  Panday Market Complex.</p>
                        <p style={{ color: "maroon" }} class="card-text mb-1"> Raghunathganj, Murshidabad, West Bengal, 742225, India</p>
                        <a href='https://www.google.com/maps/@24.4585507,88.0605225,0a,55.7y,347.98h,100.82t/data=!3m4!1e1!3m2!1szUnxmXjcAeHEw-Nl9jo6cQ!2e0?source=apiv3' target="_blank" rel="noopener noreferrer">
                          <p class='mb-2' >Direction</p>
                          </a>
                        <a class="appbuttonmanmohinicall me-1" href="tel: 97335 08786">+91 9091070100 </a>
                      </div>
                    </div>
                  </div>
                  
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        MANMOHINI BOLPUR
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">

                        <p style={{ color: "maroon" }} class="card-text mb-1">294/227, Sriniketan Road, Bolpur,</p>
                        <p style={{ color: "maroon" }} class="card-text mb-2">  Birbhum, West Bengal, 731204, India</p>   
                        <a href='https://www.google.com/maps?ll=23.662784,87.695895&z=12&t=m&hl=en&gl=US&mapclient=embed&cid=8486267855774695207' target="_blank" rel="noopener noreferrer">
                          <p class='mb-2' >Direction</p>
                          </a>  
                        
                        <a class="appbuttonmanmohinicall me-0" href="tel: 97335 08786">+91 9233515740 </a>           
                              </div>
                     
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        MANMOHINI MALDA
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p style={{ color: "maroon" }} class="card-text mb-1">Rathbari, NH34, Malda</p>
                        <p style={{ color: "maroon" }} class="card-text mb-2">  West Bengal, 732101, India</p>
                        <a href='https://www.google.com/maps?ll=25.005281,88.136257&z=12&t=m&hl=en&gl=US&mapclient=embed&cid=2909829734188020646' target="_blank" rel="noopener noreferrer">
                          <p class='mb-2' >Direction</p>
                          </a>  
                        <a class="appbuttonmanmohinicall me-5" href="tel: 97335 08786">+91 7063456789</a>
                      </div>
                    </div>
                  </div>
                </div>

               
                {/* <a href="tel: +91 9093168565" class="btn btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 9093168565</a>
                <br />
                <a href="tel: +91 9434168565" class="btn btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 9434168565</a> <br />
                <ReactWhatsapp number="+91 9434168565" class="btn btn-success" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp> */}

              </ul>
            </div>

            <div class="col-md-3">
              <h6 style={{ color: "maroon" }}>Products</h6>
              <hr />
              <ul class="list-unstyled">




                <a style={{ color: "maroon" }} href="Mens-wear">
                  <li style={{ color: "maroon" }} class="mb-2"><a ><small>MENS WAER</small></a></li></a>
                <a style={{ color: "maroon" }} href="womens">
                  <li style={{ color: "maroon" }} class="mb-2"><a ><small> WOMENS WEAR</small></a></li></a>
                <a style={{ color: "maroon" }} href="kidswear">
                  <li style={{ color: "maroon" }} class="mb-2"><a><small>KIDS WEAR</small></a></li></a>
   




              </ul>

            </div>
            <div class="col-md-3">

              <h6 style={{ color: "maroon" }}>Quick Link</h6>
              <hr />

              <ul class="list-unstyled">

                <a href='contact' style={{ color: "maroon" }} >
                  <li style={{ color: "maroon" }} class="mb-2"><small>Contact </small></li></a>
                  <a href='career' style={{ color: "maroon" }} >
                  <li style={{ color: "maroon" }} class="mb-2"><small>Career </small></li></a>

              </ul>

              <br/>
              <br/>
              <h6 style={{ color: "maroon" }}>Legal </h6>
              <hr />
              <ul class="list-unstyled">
              <Link style={{ color: "maroon" }} to="#">
                  <li style={{ color: "maroon" }} class="mb-2"><small>Terms & Condition</small></li></Link>
                  <Link style={{ color: "maroon" }} to="#">
                  <li style={{ color: "maroon" }} class="mb-2"><small>Privacy Policy </small></li></Link>
                  <Link style={{ color: "maroon" }} to="#">
                  <li style={{ color: "maroon" }} class="mb-2"><small>Shipping Policy </small></li></Link>
                  </ul>

            </div>
            <div class="col-md-3">
              <h6 style={{ color: "maroon" }}>Social Link</h6>
              <hr />
              <ul class="list-unstyled">
                <li class="mb-2"> <a style={{ color: "blue" }} href="https://www.facebook.com/manmohini.textile" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "blue" }} class="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg> <small> Facebook</small></a> </li>

                <li class="mb-2"> <a style={{ color: "red" }} href="https://www.youtube.com/@Manmohinitextileprivatelimited" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "red" }} class="bi bi-youtube" viewBox="0 0 16 16">
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                  </svg> <small> Youtube</small></a> </li>






              </ul>

            </div>





          </div>
        </div>
      </div>


    
      <div class="album py-3">

        <div class="container">

          <div class="row">
            <div class="col-md-5">
             
        <a href='https://manmohini.rewardz.app/RewardsApp/#/login' target="_blank" rel="noopener noreferrer" class="appbuttonmanmohini" type="submit">Rewards App <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16">
  <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27"/>
</svg>  </a>
            </div>

            <div class="col-md-3">
              <p style={{ color: "maroon" }}> Copyright © 2024, MANMOHINI</p>

            </div>
            <div class="col-md-4">
                 <small style={{color:"maroon"}}> Development & Design By: <a style={{color:"maroon"}} href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> MR. INDIAN CODER</a> </small>
                <small>  <a  href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> </a> </small>
                <br/>
                <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="60" height="22" alt="mr.indiancoder"/> </a>
                 </div>

          </div>
        </div>
      </div>

    </div>
    //  </div>





  );
}
export default Footer;