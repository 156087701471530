import React from 'react'
import Navbar from '../inc/Navbar'
import Manmohinibolpur from "../inc/Manmohinibolpur"
function Bolpur() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
     
        <Manmohinibolpur/>
       <br/>
        <div className="album py-0">

<div className="container">
    <h3>Photo & Content</h3>
    </div>
    </div>
    <br/>

        <div className="album py-0">

<div className="container">

    <div className="row">
    <iframe className="iframe-fluid mb-3" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Manmohini%20Textile%20Pvt%20Ltd%20-%20Bolpur,%20Bolpur%20-%20Sriniketan%20Rd,%20Darjipara,%20Bolpur,%20West%20Bengal%20731204%20+(Manmohini%20Textile%20Pvt%20Ltd%20-%20Bolpur,%20Bolpur%20-%20Sriniketan%20Rd,%20Darjipara,%20Bolpur,%20West%20Bengal%20731204)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 
    <iframe src="https://www.google.com/maps/embed?pb=!4v1710945502438!6m8!1m7!1seNEDBTILm6UcBXr7smGLWQ!2m2!1d23.66275277930672!2d87.69576869174973!3f61.54241463477601!4f19.18337651119893!5f1.730280556342711" className="iframe-fluid mb-2" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    </div>
</div>
</div>
<br/>

    </div>
  )
}

export default Bolpur