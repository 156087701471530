import React from 'react'
import Navbar from "../inc/Navbar";
import AddCareer from '../AddCareer';
import {Link} from "react-router-dom";

import ReactWhatsapp from "react-whatsapp";
import Careerupdate from './Careerupdate';

function Career() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            
            <br />
            <div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"black"}} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Career <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-2'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mt-3">
                          <Careerupdate/>
                        </div>
                        <div className="col-md-7 mt-4">
                            <AddCareer/>
                            <br/>
                            <br/>
                            <div className="d-flex justify-content-start align-items-center mb-2">
                            <a className="me-3" href="mailto: girishgulgulia65@gmail.com">Auto Mail (Click Now)</a>
                            <ReactWhatsapp number="+91 9733508786" className="btn btn-success btn-sm" message="I am Interested"> Auto WhatsApp </ReactWhatsapp>


                                </div>

                        </div>
                    </div>
                </div>

            </div>
            <br/>
            <br/>
        </div>
    )
}

export default Career