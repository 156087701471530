import React from 'react'
import Navbar from '../inc/Navbar'
import Maldaimage from "../images/malda.jpg";
import "./Malda.css";
import Maldarathbari from "../images/malda-rathbari.jpg"
import Maldaslider from '../inc/Maldaslider';
import Manmohiniberhampore from '../inc/Manmohiniberhampore';

function Berhampore() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <div className="album py-1">

<div className="container">
  <h6>MANMOHINI BERHAMPORE</h6>
</div>
</div>
   
  
<Manmohiniberhampore/>

      <div className="album py-3">

        <div className="container">
          <h3>Photo & Content</h3>
        </div>
      </div>
      

      {/* <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card" >
                <div className='hoverimage1'>
                  <img src={Maldaimage} className="card-img-top image2" alt="manmohini_malda" />
                  <div className="overlay1">MANMOHINI MALDA </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="card" >
                <div className='hoverimage1'>
                  <img src={Maldarathbari} className="card-img-top image2" alt="manmohini_malda" />
                  <div className="overlay1">MANMOHINI MALDA </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <br />

      <div className="album py-0">

        <div className="container">

          <div className="row">
          <iframe className="iframe-fluid mb-3" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=464X+X5P%20MANMOHINI,%20315/1%20&amp;%20316/B,%20Netaji%20Road,%20Khagra,%20Netaji%20Rd,%20Khagra,%20Berhampore,%20West%20Bengal%20742103%20+(464X+X5P%20MANMOHINI,%20315/1%20&amp;%20316/B,%20Netaji%20Road,%20Khagra,%20Netaji%20Rd,%20Khagra,%20Berhampore,%20West%20Bengal%20742103)&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 

          <iframe src="https://www.google.com/maps/embed?pb=!4v1711473232473!6m8!1m7!1sUR8XGH-5ZZJ2ha863ch58A!2m2!1d24.11199986698407!2d88.24794671815486!3f253.460177483163!4f8.653847185158966!5f1.3813578720068418"  height="480" className="iframe-fluid mb-1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <br />

    </div>
  )
}

export default Berhampore