import React from 'react'
import Navbar from '../inc/Navbar'
import Maldaimage from "../images/malda.jpg";
import "./Malda.css";
import Maldarathbari from "../images/malda-rathbari.jpg"
import Maldaslider from '../inc/Maldaslider';
function Malda() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
   
  
<Maldaslider/>

      <div className="album py-3">

        <div className="container">
          <h3>Photo & Content</h3>
        </div>
      </div>
      

      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card" >
                <div className='hoverimage1'>
                  <img src={Maldaimage} className="card-img-top image2" alt="manmohini_malda" />
                  <div className="overlay1">MANMOHINI MALDA </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="card" >
                <div className='hoverimage1'>
                  <img src={Maldarathbari} className="card-img-top image2" alt="manmohini_malda" />
                  <div className="overlay1">MANMOHINI MALDA </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-0">

        <div className="container">

          <div className="row">
            <iframe className="iframe-fluid mb-3" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Monmohini%20Fashion%20Store,%20NH%2034,%20Station%20Rd,%20opposite%20hotel%20kalinga,%20Malda,%20West%20Bengal%20732101%20+(Monmohini%20Fashion%20Store,%20NH%2034,%20Station%20Rd,%20opposite%20hotel%20kalinga,%20Malda,%20West%20Bengal%20732101)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 


            <iframe src="https://www.google.com/maps/embed?pb=!4v1710945874276!6m8!1m7!1sFW0nWjlvEEZ70AJLRzPxqw!2m2!1d25.00522874638043!2d88.13659590612305!3f305.4176405395494!4f14.21369930293561!5f0.7820865974627469" className="iframe-fluid mb-2" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <br />

    </div>
  )
}

export default Malda