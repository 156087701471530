import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Mens from "../images/MENS.jpg"
import WoMens from "../images/women-card.jpg"
import Kids from "../images/kids.jpg"
import Cosmetics from "../images/cosmentics.jpg"
import accessories from "../images/accessories.jpg";

// import Breclet from "../images/Kids Wear.jpg";

// import Locket from "../images/locket.jpg";



import "./Products.css";

import { Link } from 'react-router-dom';


function Products() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    return (
        <div>
          

            <div className="animat">
                <Slider {...settings}>
                    <div>

                        <Link to="/Mens-wear">
                            <img src={Mens} className="card-img-top mb-2" alt="Stoneberhampore" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Mens Wear</p>
                                <Link to="/Mens-wear">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>
                    </div>

                    <div>



                        <Link to="/womens">
                            <img src={WoMens} className="card-img-top mb-2" alt="menswear" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Womens Wear</p>
                                <Link to="/womens">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>
                    </div>


                    <div>


                        <Link to="/kidswear">
                            <img src={Kids} className="card-img-top mb-2" alt="manmohini" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Kids Wear</p>
                                <Link to="/kidswear">
                                    <p className="card-text" style={{ color: "grey" }}> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>

                    </div>

                    <div>


                        <a href='Cosmetics' >
                            <img src={Cosmetics} className="card-img-top mb-2" alt="manmohini" />
                        </a>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Cosmetic</p>
                                <a href='Cosmetics' >
                                    <p style={{ color: "grey" }} className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </a>
                            </center>
                        </div>

                    </div>
                    <div>


                        <Link to="#">
                            <img src={accessories} className="card-img-top mb-2" alt="manmohini" />
                        </Link>
                        <div className="card-body">
                            <center>
                                <p style={{ color: "maroon" }} className="card-text mb-0">Accessories</p>
                                <Link to="#">
                                    <p style={{ color: "grey" }} className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg></span></small></p> </Link>
                            </center>
                        </div>

                    </div>





                    <div>


                    </div>


                </Slider>
            </div>

        </div>
    )
}



export default Products
