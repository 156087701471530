import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

import "./About.css";


function About2() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [announce, setAnnounce] = useState([])
    const navigate = useNavigate()

    const announceCollectionRef = collection(db, "ABOUT-US");
    useEffect(() => {

        const getAnnounce = async () => {
            const data = await getDocs(announceCollectionRef);
            setAnnounce(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAnnounce()
    }, [])
    return (
        <div>

            {announce.filter((val) => {
                if (setsearchTerm === "") {
                    return val;
                } {
                    return val;
                }
            }).slice(pagination.start, pagination.end).map((manmohini) => {
                return (



                    <div className="container">

                        <div className="row">

                            <div className="col-md-6">
                                <div className="card" >
                                <iframe src="https://www.google.com/maps/embed?pb=!4v1711473232473!6m8!1m7!1sUR8XGH-5ZZJ2ha863ch58A!2m2!1d24.11199986698407!2d88.24794671815486!3f253.460177483163!4f8.653847185158966!5f1.3813578720068418"  height="340" className="iframe-fluid mb-1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    <div className="card-body">
                                        <h5 className="card-title">{manmohini.title}</h5>
                                        <p className="card-text">{manmohini.address}</p>
                                        <a href="https://www.google.com/maps/place/MANMOHINI/@24.111918,88.24783,10z/data=!4m6!3m5!1s0x39f97ddc54d49589:0xa412e9b56d898975!8m2!3d24.1119182!4d88.24783!16s%2Fg%2F1thl3vbb?hl=en&entry=ttu" target="_blank" rel="noopener noreferrer" className="btn-grad16">Direction</a>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="card" >
                                   
                                    <div className="card-body">
                                        <h5 className="card-title">{manmohini.details}</h5>
                                        <h6 className="card-text">{manmohini.des}</h6>
                                        <p className="card-text">{manmohini.des2}</p>

                                      
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>






                )
            })}

            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={announce.length}
                /> */}

        </div>
    )
}

export default About2
