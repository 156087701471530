import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import {Link} from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
 
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";



function Kurtaset () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(100);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [saree, setSaree] = useState([])
      const navigate = useNavigate()
  
      const sareeCollectionRef = collection(db, "WOMENS-WEAR Kurta Set");
      useEffect(() => {
  
          const getSaree = async () => {
              const data = await getDocs(sareeCollectionRef);
              setSaree(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getSaree()
      }, [])
    return (
      
<>
<Navbar/>

<br/>
<ScrollToTop smooth top="100" color="maroon" />

<br/>
<br/>
<br/>



<div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"black"}} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/womens" style={{color:"black"}} >Back</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Kurta Set <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {saree.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 

      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((manmohini) => { return (
            <div className="col-md-4 mt-3" >
                        <div className="card mb-4 ">
                        <img  className="card-img-top" src={manmohini.img}  alt="Top & Teas"/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{manmohini.title}</b></h6>
                                < h5 style={{color: "Darkblue"}}className="card-text"> ₹{manmohini.price} </h5>

                                <p className="card-text mb-2">{manmohini.des}</p>

                                <div className="d-flex justify-content-between align-items-center">
                              
                              <a href='tel: 97335 08786' className="whatsappbook mb-3">Call Book</a>
                              <ReactWhatsapp number="+91 9733508786" className="whatsappbook mb-3" message="I want to buy this shirt, contact me"> Whatsapp Inquiry</ReactWhatsapp>
                          
                           </div>
                                <button type="button" className="btn btn btn-dark me-1">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>  < FacebookShareButton url="https://www.manmohini.com/Kurtaset" className="me-2" quote={"manmohini"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.manmohini.com/Kurtaset" className="me-2" quote={"manmohini"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.manmohini.com/Kurtaset" className="me-2" quote={"manmohini"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.manmohini.com/Kurtaset" className="me-2" quote={"manmohini"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.manmohini.com/Kurtaset" quote={"manmohini"} hastag={"#WebApp"}
>
 <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                      
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={dul.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Kurtaset;



