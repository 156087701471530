import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Mens from './components/pages/Mens';
import Shirts from './components/pages/Shirts';
import Aboutus from './components/pages/Aboutus';
import Raghunathganj from './components/pages/Raghunathganj';
import Bolpur from './components/pages/Bolpur';
import Malda from './components/pages/Malda';
import Contactus from './components/pages/Contactus';
import Womens from './components/pages/Women';
import Saree from './components/pages/Saree';
import Berhampore from './components/pages/Berhampore';
import Kids from './components/pages/Kids';
import Kidstshirt from './components/pages/Kidstshirt';
import Blazer from './components/pages/Blazer';
import MenPanjabi from './components/pages/MenPanjabi';
import MenJeans from './components/pages/MenJeans';
import Cosmetics from './components/pages/Cosmetics';
import Beauty from './components/pages/Beauty';
import Ladiesbag from './components/pages/Ladiesbag';
import MensTshirt from './components/pages/MensTshirt';
import MenTrack from './components/pages/MenTrack';
import MenTrousers from './components/pages/MensTrousers';
import MensEthnic from './components/pages/MensEthnic';
import Mensunderg from './components/pages/Mensunderg';
import Topteas from './components/pages/Topteas';
import Lehenga from './components/pages/Lehenga';
import Dresses from './components/pages/Dresses';
import Jeans from './components/pages/Jeans';
import Kurtaset from './components/pages/Kurtaset';
import Ethnic from './components/pages/Ethnic';
import Trousers from './components/pages/Trousers';
import Lingeria from './components/pages/Lingeria';
import Girlstop from './components/pages/Girlstop';
import Career from './components/pages/Career';
import Winterpage from './components/pages/Winterpage';



function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>


      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Mens-wear" element={<Mens/>}/>
      <Route axact path="/womens" element={<Womens/>}/>
      <Route axact path="/kidswear" element={<Kids/>}/>
      <Route axact path="/shirts" element={<Shirts/>}/>
      <Route axact path="/t-shirt" element={<Kidstshirt/>}/>
      <Route axact path="/saree" element={<Saree/>}/>
      <Route axact path="/aboutus" element={<Aboutus/>}/>
      <Route axact path="/Raghunathganj" element={<Raghunathganj/>}/>
      <Route axact path="/Bolpur" element={<Bolpur/>}/>
      <Route axact path="/malda" element={<Malda/>}/>
      <Route axact path="/contact" element={<Contactus/>}/>
      <Route axact path="/berhampore" element={<Berhampore/>}/>
      <Route axact path="/Blazer" element={<Blazer/>}/>
      <Route axact path="/Punjabi" element={<MenPanjabi/>}/>
      <Route axact path="/Jeans" element={<MenJeans/>}/>
      <Route axact path="/Cosmetics" element={<Cosmetics/>}/>
      <Route axact path="/hair-and-beauty" element={<Beauty/>}/>
      <Route axact path="/Ladiesbag" element={<Ladiesbag/>}/>
      <Route axact path="/mens-tshirt" element={<MensTshirt/>}/>
      <Route axact path="/track-pants" element={<MenTrack/>}/>
      <Route axact path="/trousers" element={<MenTrousers/>}/>
      <Route axact path="/Ethnic-Wear" element={<MensEthnic/>}/>
      <Route axact path="/undergarments" element={<Mensunderg/>}/>
      <Route axact path="/top-teas" element={<Topteas/>}/>
      <Route axact path="/Lehenga" element={<Lehenga/>}/>
      <Route axact path="/dresses" element={<Dresses/>}/>
      <Route axact path="/Jeans-Women" element={<Jeans/>}/>
      <Route axact path="/Kurtaset" element={<Kurtaset/>}/>
      <Route axact path="/Women-Ethnic-wear" element={<Ethnic/>}/>
      <Route axact path="/Women-Trousers" element={<Trousers/>}/>
      <Route axact path="/Lingeria" element={<Lingeria/>}/>
      <Route axact path="/girls-top" element={<Girlstop/>}/>
      <Route axact path="/career" element={<Career/>}/>
      <Route axact path="/best-winter-collection" element={<Winterpage/>}/>
 
    
      </Routes>
    <Footer/>
  
    </div>

    </Router>


  );
}

export default App;
