
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDkyfiCNdoTI4BNxhJBfpbsae5H6L5NT-0",
    authDomain: "manmohini-textile-4b3f8.firebaseapp.com",
    projectId: "manmohini-textile-4b3f8",
    storageBucket: "manmohini-textile-4b3f8.appspot.com",
    messagingSenderId: "160842922215",
    appId: "1:160842922215:web:9b22638b25e4b853e00a42",
    measurementId: "G-2Y83B4RNRW"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;