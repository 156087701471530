import React from 'react'
import Navbar from '../inc/Navbar'
import Shirt from "../images/shirt.jpg"
import Tshirt from "../images/t-shirt.jpg";
import blazers from "../images/blazers.jpg";

import ScrollToTop from "react-scroll-to-top";
import Panjabi from "../images/panjabi-manmohini.jpg";
import Jeans from "../images/jeans-pant.jpg";
import Trackpants from "../images/TRACK PANT.jpg";
import Trousers from "../images/trousers-mens.jpg";
import EthnicWear from "../images/Ethnic wear.jpg";
import Undergarments from "../images/undergarments.jpg";
import ReactWhatsapp from "react-whatsapp";
import { Link } from 'react-router-dom';
import shirt1 from "../images/shirt.jpg";


function Mens() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <div className='cloth'>
                <div className='album py-1'>
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{ color: "black" }} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Mens <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='shirts'>
                                        <div className="card">
                                            <img src={shirt1} className="card-img-top" alt="Locketnew" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 style={{ color: "darkblue" }} className="card-title">Shirt</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content...</p>
                                                    <a href="shirts" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='mens-tshirt'>
                                        <div className="card">
                                            <img src={Tshirt} className="card-img-top" alt="Hansh" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">T-Shirt</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="mens-tshirt" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Blazer'>
                                        <div className="card">
                                            <img src={blazers} className="card-img-top" alt="manmohini"/>
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Blazers</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <Link to="/Blazer" className="appbuttonmanmohinicall">Learn More</Link>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Punjabi'>
                                        <div className="card">
                                            <img src={Panjabi} className="card-img-top" alt="Punjabi"/>
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Punjabi</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content...</p>
                                                    <a href="Punjabi" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Jeans'>
                                        <div className="card">
                                            <img src={Jeans} className="card-img-top" alt="Jeans"/>
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Jeans</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="Jeans" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='track-pants'>
                                        <div className="card">
                                            <img src={Trackpants} className="card-img-top" alt="trackpant"/>
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Track Pants</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="track-pants" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='trousers'>
                                        <div className="card">
                                            <img src={Trousers} className="card-img-top" alt="Trousers" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Trousers</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="trousers" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Ethnic-Wear'>
                                        <div className="card">
                                            <img src={EthnicWear} className="card-img-top" alt="EthnicWear" />
                                            <div className="card-body">
                                                <center>
                                                    <h5 className="card-title">Ethnic Wear</h5>
                                                    <p style={{ color: "grey" }} className="card-text">Description content....</p>
                                                    <a href="Ethnic-Wear" className="appbuttonmanmohinicall">Learn More</a>
                                                </center>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <ScrollToTop smooth top="100" color="maroon" />

            <br />
            <br />
        </div>
    )
}

export default Mens