import React from 'react'
import Navbar from '../inc/Navbar'

import Raghunathganjmanmohini from '../inc/Raghunathganjmanmohini';

function Raghunathganj() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <div className="album py-1">

<div className="container">
  <h6>MANMOHINI BERHAMPORE</h6>
</div>
</div>
     <Raghunathganjmanmohini/>
        
       
        <div className="album py-3">

<div className="container">
    <h3>Photo & Content</h3>
    </div>
    </div>


        <div className="album py-0">

<div className="container">

    <div className="row">
    <iframe className="iframe-fluid mb-3" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Manmohini%20Textile%20Pvt%20Ltd%20-%20Raghunathganj,%20Bus%20Stand,%20739/620/1,%20Lalgola%20Maharaja%20Rd,%20opposite%20Raghunathganj,%20Raghunathganj,%20Basudebpur,%20West%20Bengal%20742225%20+(Manmohini%20Textile%20Pvt%20Ltd%20-%20Raghunathganj,%20Bus%20Stand,%20739/620/1,%20Lalgola%20Maharaja%20Rd,%20opposite%20Raghunathganj,%20Raghunathganj,%20Basudebpur,%20West%20Bengal%20742225)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> 
    <iframe src="https://www.google.com/maps/embed?pb=!4v1710944171583!6m8!1m7!1szUnxmXjcAeHEw-Nl9jo6cQ!2m2!1d24.45855074993163!2d88.06052250518118!3f347.98171332315155!4f10.816328164130965!5f1.5066661918444901" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
</div>
<br/>

    </div>
  )
}

export default Raghunathganj